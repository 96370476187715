import { TRAVEL_MODE } from "main/types/directions";
import { isClearDirectionsAction, isUpdateDirectionsInfoAction, isUpdateOriginAction, isUpdateStoreAction, isUpdateTravelModeAction, isUpdateUserPositionAction, } from "./actions";
export function reducer(state, action) {
    if (isClearDirectionsAction(action)) {
        return {
            ...state,
            store: undefined,
            origin: state.userPosition,
            directionsInfo: undefined,
            travelMode: TRAVEL_MODE.DRIVING,
        };
    }
    if (isUpdateUserPositionAction(action)) {
        return {
            ...state,
            userPosition: action.payload.userPosition,
            origin: action.payload.userPosition,
        };
    }
    if (isUpdateStoreAction(action)) {
        return {
            ...state,
            store: action.payload.store,
            directionsInfo: undefined,
        };
    }
    if (isUpdateDirectionsInfoAction(action)) {
        return {
            ...state,
            directionsInfo: action.payload.directionsInfo,
        };
    }
    if (isUpdateOriginAction(action)) {
        return {
            ...state,
            origin: action.payload.origin,
        };
    }
    if (isUpdateTravelModeAction(action)) {
        return {
            ...state,
            travelMode: action.payload.travelMode,
        };
    }
    return state;
}
