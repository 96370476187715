export function isStyledNode(node) {
    return "className" in node;
}
export function isAutocompleteNode(node) {
    return "autocompleteType" in node;
}
export function isNodes(representation) {
    return Array.isArray(representation);
}
export function isNode(representation) {
    return !isNodes(representation);
}
