import classNames from "classnames";
import React, { useState } from "react";
import "./Menu.css";
export default function AccordionMenu({ children, label }) {
    const [isOpen, setOpen] = useState(false);
    function handleMenuClick() {
        setOpen((prev) => !prev);
    }
    return (React.createElement("div", { className: "rt-accordion-menu" },
        React.createElement("div", { className: classNames("rt-accordion-menu__main", {
                "rt-accordion-menu__main--open": isOpen,
            }), onClick: handleMenuClick }, label),
        React.createElement("div", { className: classNames("rt-accordion-menu__items", { "rt-accordion-menu__items--visible": isOpen }) }, isOpen && children)));
}
