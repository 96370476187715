import React from "react";
import { loadRepresentation } from "core/parser";
import { useStores } from "core/context/stores";
function Component({ children, configuration }) {
    const { visibleStores, optionals: { openingTimesCache }, } = useStores();
    return React.createElement("ul", { className: "rt-stores" }, children(visibleStores, openingTimesCache !== null && openingTimesCache !== void 0 ? openingTimesCache : new Map()));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
    children: (repr, config) => (stores, openingTimesCache) => stores.map((store) => loadRepresentation(repr, config, { key: store.storeCode, store, openingTimesCache })),
};
