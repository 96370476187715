export var GeolocationErrors;
(function (GeolocationErrors) {
    GeolocationErrors["ACCURACY_NOT_ENOUGH"] = "Position accuracy is not enough";
    GeolocationErrors["GEOLOCATION_DISABLED"] = "Geolocation is disabled in browser";
    GeolocationErrors["BAD_PREDICTION"] = "Selected prediction is missing either latitude or longitude information";
})(GeolocationErrors || (GeolocationErrors = {}));
export function missingProps(componentName) {
    return `* MISSING PROPS: props missing for '${componentName}' component`;
}
export function missingMandatoryProp(componentName, propName) {
    return `* MISSING MANDATORY PROP: mandatory prop '${propName}' is missing from component '${componentName}'`;
}
export var OpeningsErrors;
(function (OpeningsErrors) {
    OpeningsErrors["CASE_NOT_DEFINED"] = "Opening case is not defined";
    OpeningsErrors["BAD_PARAMS"] = "Bad parameters provided to the opening text function";
    OpeningsErrors["BAD_HOURS_REPRESENTATION"] = "Bad string representation for opening hours";
})(OpeningsErrors || (OpeningsErrors = {}));
