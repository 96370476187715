import { createPosition } from "main/utils/position";
import { createContext, useContext } from "react";
const dummyPosition = createPosition({ latitude: 44.8054695, longitude: 10.3367158 });
export const PositionContext = createContext({
    defaultPosition: dummyPosition,
    userPosition: dummyPosition,
    currentPosition: dummyPosition,
    geolocateUser: async () => { },
    updateCurrentPosition: (pos) => { },
});
PositionContext.displayName = "PositionContext";
export const usePosition = () => useContext(PositionContext);
