import { loadRepresentation } from "core/parser";
import React, { useRef } from "react";
function Component({ children, ...rest }) {
    const elRef = useRef(null);
    return (React.createElement("div", { ref: elRef }, children({ ...rest, portalAnchor: elRef.current })));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (_) => { },
    children: (repr, config) => (props) => loadRepresentation(repr, config, props)
};
