import React from "react";
import { loadRepresentation } from "core/parser";
function Component({ children, className, ...rest }) {
    return React.createElement("div", { className: className }, children(rest));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
    children: (repr, config) => (props) => loadRepresentation(repr, config, props),
};
