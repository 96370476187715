import React from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
import { PositionType } from "types/position";
import { validateHostname } from "main/initialization";
import { createPosition } from "utils/position";
import { findExternalResources, parseRepresentation, loadRepresentation } from "core/parser";
import { getPositionData, LOCAL_STORAGE_USER_POSIITON } from "main/utils/local-storage";
import { StoreLocatorGAManager } from "main/utils/services/google/analytics";
import { analyticsHandler } from "core/events";
async function configureStoreLocator(userConfig) {
    var _a;
    if (!userConfig.apiKey)
        throw new Error("Missing RT-API key");
    if (!userConfig.language)
        throw new Error("Missing language param");
    const hostname = await validateHostname(userConfig.apiKey);
    const representationPromise = axios.get(`${hostname}data/representation.json`);
    const configurationPromise = axios.get(`${hostname}data/configuration.json`);
    const [representationResponse, configurationResponse] = await Promise.all([
        representationPromise,
        configurationPromise,
    ]);
    const representation = representationResponse.data;
    const configuration = configurationResponse.data;
    // parsing dynamic components
    await parseRepresentation(representation);
    // using parsed representation to load external dependencies
    const externalResources = [];
    findExternalResources(representation, externalResources, configuration.api);
    const priorities = [...new Set(externalResources.map(r => r.priority))];
    for (let i = 0; i < priorities.length; ++i) {
        const promises = externalResources
            .filter(r => r.priority === priorities[i])
            .map(r => new Promise(r.load));
        await Promise.all(promises);
    }
    console.log(representation);
    const p1 = axios.get(`${hostname}js_db/stores-json-${userConfig.language}.json`);
    const p2 = axios.get(`${hostname}js_db/services-json-${userConfig.language}.json`);
    const p4 = axios.post(`${hostname}time.php`);
    const p5 = axios.get(`${hostname}translations/translations-${userConfig.language}.json`);
    const p6 = new Promise((resolve, reject) => {
        const style = document.createElement("link");
        style.rel = "stylesheet";
        style.href = `${hostname}data/style.css`;
        style.onload = () => resolve("OK");
        style.onerror = e => reject(e);
        document.head.appendChild(style);
    });
    const [storesRes, servicesRes, serverTime, translations] = await Promise.all([
        p1,
        p2,
        p4,
        p5,
        p6,
    ]);
    const defaultPosition = createPosition({
        latitude: 45.4639866,
        longitude: 9.1886755,
        src: PositionType.DEFAULT,
    });
    configuration.hostname = hostname;
    configuration.language = userConfig.language;
    configuration.time = serverTime.data;
    configuration.translations = translations.data;
    console.log("configuration: ", configuration);
    let userPosition = getPositionData(LOCAL_STORAGE_USER_POSIITON);
    if (!userPosition)
        userPosition = defaultPosition;
    const currentPosition = (_a = userConfig.preselectedPosition) !== null && _a !== void 0 ? _a : userPosition;
    const props = {
        allStores: storesRes.data,
        allServices: servicesRes.data,
        defaultPosition,
        userPosition,
        currentPosition,
        preselectedFilters: userConfig.preselectedFilters,
        preselectedStoreCode: userConfig.preselectedStoreCode,
    };
    const gaManager = new StoreLocatorGAManager(configuration);
    analyticsHandler.dispatch({
        type: "pageview",
        subtype: "home",
    });
    return loadRepresentation(representation, configuration, props);
}
window.rtInitSL = async function (userConfig) {
    configureStoreLocator(userConfig)
        .then(RootComponent => {
        try {
            const anchor = document.getElementById(userConfig.anchorId);
            const root = createRoot(anchor);
            root.render(React.createElement("div", { className: "rt-store-locator" }, RootComponent));
        }
        catch (e) {
            console.error(`element of id "${userConfig.anchorId}" is not present in the DOM`);
        }
    })
        .catch(e => {
        console.error(`An error occurred during setup: ${e}`);
    });
};
