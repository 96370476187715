export function selectPredicate(filterType, filterValue, logic) {
    switch (filterType) {
        case "storeTypes":
            return getStoreTypesPredicate(filterValue, logic);
        case "cities":
            return getCityPredicate(filterValue, logic);
        case "provinces":
            return getProvincePredicate(filterValue, logic);
        case "countries":
            return getCountryPredicate(filterValue, logic);
        case "services":
            return getServicesPredicate(filterValue, logic);
        default:
            throw new Error(`Cannot select predicate for filterType: ${filterType}`);
    }
}
function getStoreTypesPredicate(filterValue, logic) {
    // filterValue -> string[]
    return logic === "and"
        ? (s) => s.storeTypes.every((type) => filterValue.includes(type))
        : (s) => s.storeTypes.some((type) => filterValue.includes(type));
}
function getCityPredicate(filterValue, _) {
    // filterValue -> string[]
    // logic different from OR makes no sense
    const cities = filterValue.map((city) => city.trim().toLocaleLowerCase());
    return (s) => {
        const storeCity = s.city.trim().toLocaleLowerCase();
        return cities.some((city) => city === storeCity);
    };
}
function getProvincePredicate(filterValue, _) {
    // filterValue -> string[]
    // logic different from OR makes no sense
    const provinces = filterValue.map((prov) => prov.trim().toLocaleLowerCase());
    return (s) => {
        const storeProv = s.province.trim().toLocaleLowerCase();
        return provinces.some((prov) => prov === storeProv);
    };
}
function getCountryPredicate(filterValue, _) {
    // filterValue -> string[]
    // logic different from OR makes no sense
    const countries = filterValue.map((country) => country.trim().toLocaleLowerCase());
    return (s) => {
        const storeCountry = s.country.tagISO31661Alpha2.trim().toLocaleLowerCase();
        return countries.some((country) => country === storeCountry);
    };
}
function getServicesPredicate(filterValue, logic) {
    // filterValue -> number[]
    return logic === "and"
        ? (s) => filterValue.every((service) => s.services.includes(service))
        : (s) => filterValue.some((service) => s.services.includes(service));
}
