import React, { useEffect, useMemo, useState } from "react";
import { UserConsentContext } from "core/context/userConsent";
import { getConsentData, LOCAL_STORAGE_USER_COOKIE_CONSENT, LOCAL_STORAGE_USER_POSITION_CONSENT, saveExpirable, } from "main/utils/local-storage";
import { ExpirationTime } from "main/utils/time-expiration";
import { ExpirationType } from "main/types/time-expiration";
export function Component({ children }) {
    // user consent to his position usage
    const [positionConsent, setPositionConsent] = useState(() => !!getConsentData(LOCAL_STORAGE_USER_POSITION_CONSENT));
    useEffect(() => {
        saveExpirable(LOCAL_STORAGE_USER_POSITION_CONSENT, {
            consent: positionConsent,
            expiration: new ExpirationTime(1, ExpirationType.YEARS).timeInMillis(),
        });
        if (positionConsent) {
            setPositionAlertVisbile(false);
        }
    }, [positionConsent]);
    // visibility of some kind of alert showing info on the consent
    const [isPositionAlertVisible, setPositionAlertVisbile] = useState(!positionConsent);
    // user consent to cookies and storages usage
    const [storageConsent, setStorageConsent] = useState(() => !!getConsentData(LOCAL_STORAGE_USER_COOKIE_CONSENT));
    useEffect(() => {
        saveExpirable(LOCAL_STORAGE_USER_COOKIE_CONSENT, {
            consent: storageConsent,
            expiration: new ExpirationTime(1, ExpirationType.YEARS).timeInMillis(),
        });
        if (storageConsent) {
            setStorageAlertVisbile(false);
        }
    }, [storageConsent]);
    // visibility of some kind of alert showing info on the consent
    const [isStorageAlertVisible, setStorageAlertVisbile] = useState(!storageConsent);
    const contextValue = useMemo(() => ({
        positionConsent,
        setPositionConsent,
        isPositionAlertVisible,
        setPositionAlertVisbile,
        storageConsent,
        setStorageConsent,
        isStorageAlertVisible,
        setStorageAlertVisbile, // setters do not need to be a dependency
    }), [positionConsent, isPositionAlertVisible, storageConsent, isStorageAlertVisible]);
    return React.createElement(UserConsentContext.Provider, { value: contextValue }, children);
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
