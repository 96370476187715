import React from "react";
import { saveExpirable } from "utils/local-storage";
import { LOCAL_STORAGE_USER_POSITION_CONSENT } from "utils/local-storage";
import { ExpirationTime } from "utils/time-expiration";
import { ExpirationType } from "types/time-expiration";
import "./ConsentModal.css";
import { useUserConsent } from "core/context/userConsent";
import { analyticsHandler } from "core/events";
function Component({ configuration }) {
    const { isPositionAlertVisible, setPositionAlertVisbile, setPositionConsent } = useUserConsent();
    function handleUserConsent(consent) {
        if (consent) {
            setPositionConsent(consent);
            const userConsentData = {
                consent,
                expiration: new ExpirationTime(1, ExpirationType.YEARS).timeInMillis(),
            };
            saveExpirable(LOCAL_STORAGE_USER_POSITION_CONSENT, userConsentData);
        }
        setPositionAlertVisbile(false);
        analyticsHandler.dispatch({
            type: "geolocation_consent",
            subtype: consent
        });
    }
    return (React.createElement(React.Fragment, null, isPositionAlertVisible && (React.createElement("div", { id: "consent_modal", className: "rt-consent-modal-overlay" },
        React.createElement("div", { className: "rt-consent-modal-popup" },
            React.createElement("p", { className: "rt-consent-modal-popup__title" }, configuration.translations.consentModal.title),
            React.createElement("p", { className: "rt-consent-modal-popup__disclaimer" }, configuration.translations.consentModal.disclaimer),
            React.createElement("div", { className: "rt-consent-modal-popup__buttons" },
                React.createElement("button", { className: "rt-consent-modal-popup__button-yes", onClick: () => handleUserConsent(true) }, configuration.translations.consentModal.yes),
                React.createElement("button", { className: "rt-consent-modal-popup__button-no", onClick: () => handleUserConsent(false) }, configuration.translations.consentModal.no)))))));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
