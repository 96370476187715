import React from "react";
import { usePosition } from "core/context/position";
import { useDirections } from "core/context/directions";
import { analyticsHandler } from "core/events";
export function Component({ configuration }) {
    const { geolocateUser } = usePosition();
    const { directions, resetDirections } = useDirections();
    function handleNearestStoresButtonClick() {
        if (directions.store && resetDirections) {
            resetDirections();
        }
        geolocateUser();
        analyticsHandler.dispatch({
            type: "click_nearest_stores",
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: "rt-nearest-stores-button", onClick: handleNearestStoresButtonClick }, configuration.translations.controls.geolocation.text)));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
