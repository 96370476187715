import React from "react";
import { useStores } from "core/context/stores";
function Component() {
    const { allServices, filter, setFilter } = useStores();
    function handleServiceClick(serviceId) {
        var _a, _b, _c, _d;
        const services = ((_a = filter.services) === null || _a === void 0 ? void 0 : _a.includes(serviceId))
            ? (_b = filter.services) === null || _b === void 0 ? void 0 : _b.filter((id) => id !== serviceId)
            : (_d = (_c = filter.services) === null || _c === void 0 ? void 0 : _c.concat([serviceId])) !== null && _d !== void 0 ? _d : [serviceId];
        setFilter((prev) => {
            if (services.length === 0) {
                delete prev.services;
            }
            else {
                prev.services = services;
            }
            return { ...prev };
        });
    }
    return (React.createElement("select", { multiple: true }, allServices.map((service) => (React.createElement("option", { key: service.id, onClick: () => handleServiceClick(service.id) }, service.name)))));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
