import React, { useEffect } from "react";
import { GridAlgorithm, MarkerClusterer } from "@googlemaps/markerclusterer";
import { EXTERNAL_RESOURCE } from "main/enums";
import { usePosition } from "core/context/position";
import { createPosition, positionToLatLng } from "main/utils/position";
import { useStores } from "core/context/stores";
import { useDirections } from "core/context/directions";
import { TRAVEL_MODE } from "main/types/directions";
import { debounce } from "core/main";
import { analyticsHandler } from "core/events";
import { sortByDistance } from "main/utils/sort";
let map;
let markers = [];
let markersMap;
let markercluster;
// directions service for driving directions
let directionsService;
// directions renderer for driving directions
let directionsRenderer;
let infoWindow;
class CustomRenderer {
    constructor(clusterIcon, clusterLabelColor) {
        this.clusterIcon = clusterIcon;
        this.clusterLabelColor = clusterLabelColor;
    }
    getClusterSize(markersCount) {
        if (markersCount < 10)
            return new google.maps.Size(40, 40);
        if (markersCount < 50)
            return new google.maps.Size(50, 50);
        if (markersCount < 100)
            return new google.maps.Size(60, 60);
        return new google.maps.Size(70, 70);
    }
    render({ count, position }) {
        return new google.maps.Marker({
            position,
            label: {
                text: count.toString(),
                color: this.clusterLabelColor,
            },
            icon: {
                url: this.clusterIcon,
                scaledSize: this.getClusterSize(count),
            },
        });
    }
}
function createMap(center, config) {
    var _a, _b, _c, _d;
    const latlngCenter = positionToLatLng(center);
    const boundsRestriction = config.map.google.boundsRestriction
        ? {
            latLngBounds: new google.maps.LatLngBounds(new google.maps.LatLng(config.map.google.boundsRestriction.sw.latitude, config.map.google.boundsRestriction.sw.longitude), new google.maps.LatLng(config.map.google.boundsRestriction.ne.latitude, config.map.google.boundsRestriction.ne.longitude)),
        }
        : undefined;
    const googleMap = document.getElementById("rt-google-map");
    map = new google.maps.Map(googleMap, {
        center: latlngCenter,
        zoom: config.map.google.zoom.initial,
        restriction: boundsRestriction,
        mapTypeControl: (_a = config.map.google.controls) === null || _a === void 0 ? void 0 : _a.mapTypeControl,
        streetViewControl: (_b = config.map.google.controls) === null || _b === void 0 ? void 0 : _b.streetViewControl,
        fullscreenControl: (_c = config.map.google.controls) === null || _c === void 0 ? void 0 : _c.fullScreenControl,
        styles: config.map.google.styles,
    });
    markersMap = {
        user: new google.maps.Marker({
            map,
            position: latlngCenter,
            icon: {
                url: config.map.google.userMarkerIcon,
                scaledSize: new google.maps.Size(40, 40),
            },
        }),
        directionsOrigin: new google.maps.Marker({
            map,
            icon: {
                url: config.map.google.userMarkerIcon,
                scaledSize: new google.maps.Size(40, 40),
            }
        }),
        destinationStore: new google.maps.Marker({
            map,
        }),
        stores: {},
    };
    const algorithm = config.map.google.markerCluster.algorithm === "grid"
        ? new GridAlgorithm({ gridSize: (_d = config.map.google.markerCluster.gridSize) !== null && _d !== void 0 ? _d : 20 })
        : undefined; // using default algorithm (supercluster)
    const renderer = config.map.google.markerCluster
        ? new CustomRenderer(config.map.google.markerCluster.icon, config.map.google.markerCluster.labelColor)
        : undefined; // using default renderer
    markercluster = new MarkerClusterer({
        map,
        markers,
        algorithm,
        renderer,
    });
    directionsService = new google.maps.DirectionsService();
    directionsRenderer = new google.maps.DirectionsRenderer({
        markerOptions: {
            visible: false,
        },
        polylineOptions: {
            strokeColor: config.map.google.directions.polyline.color,
            strokeWeight: config.map.google.directions.polyline.weight,
        },
    });
}
function updateMap(center, config) {
    if (!map)
        return;
    const latlngCenter = positionToLatLng(center);
    map.setCenter(latlngCenter);
    map.setZoom(config.map.google.zoom.onUpdate);
    markersMap.user.setPosition(latlngCenter);
}
function getTravelMode(travelMode) {
    switch (travelMode) {
        case TRAVEL_MODE.WALKING:
            return google.maps.TravelMode.WALKING;
        case TRAVEL_MODE.DRIVING:
            return google.maps.TravelMode.DRIVING;
        case TRAVEL_MODE.PUBLIC_TRANSPORT:
            return google.maps.TravelMode.TRANSIT;
        default:
            return google.maps.TravelMode.DRIVING;
    }
}
function Component({ configuration }) {
    const { currentPosition } = usePosition();
    const { filteredStores, 
    // optionals: { reactToMapChanges },
    setVisibleStores } = useStores();
    const { directions: { origin, store, travelMode }, setStore, setDirectionsInfo, } = useDirections();
    useEffect(() => {
        if (!map)
            createMap(currentPosition, configuration);
    }, []);
    useEffect(() => {
        // updates map position
        if (map)
            updateMap(currentPosition, configuration);
    }, [currentPosition]);
    function handleBoundsChanged() {
        const mapCenter = map.getCenter();
        const mapZoom = map.getZoom();
        const mapBounds = map.getBounds();
        if (!(mapCenter && mapZoom && mapBounds))
            return;
        const storePosition = { lat: 0, lng: 0 };
        const stores = filteredStores.filter(s => {
            storePosition.lat = s.latitude;
            storePosition.lng = s.longitude;
            return mapBounds.contains(storePosition);
        }).sort(sortByDistance);
        setVisibleStores(stores);
        markercluster.clearMarkers();
        markercluster.addMarkers(markers);
    }
    useEffect(() => {
        let boundsChangedMapsEventListener;
        const boundsChangedHandler = debounce(300, handleBoundsChanged);
        boundsChangedMapsEventListener = map.addListener("bounds_changed", boundsChangedHandler);
        return () => boundsChangedMapsEventListener.remove();
    }, []);
    useEffect(() => {
        // updates markers visibility
        if (markers.length === 0) {
            for (let i = 0; i < filteredStores.length; ++i) {
                const store = filteredStores[i];
                // if (markersMap.stores[store.storeCode]) {
                //   // using the cache
                //   markers.push(markersMap.stores[store.storeCode]);
                //   continue;
                // }
                const marker = new google.maps.Marker({
                    position: { lat: store.latitude, lng: store.longitude },
                });
                marker.addListener("click", () => {
                    getInfoWindow(store).open({
                        anchor: marker,
                        map,
                        shouldFocus: false,
                    });
                });
                setMarkerIcon(marker, store);
                markers.push(marker);
                // markersMap.stores[store.storeCode] = marker;
            }
            markercluster.addMarkers(markers);
        }
        // re-render visible stores if reactToMapChanges is specified
        // handleBoundsChanged();
    }, [filteredStores]);
    useEffect(() => {
        if (store) {
            // init directions
            const destination = positionToLatLng(createPosition({
                latitude: store.latitude,
                longitude: store.longitude,
            }));
            const request = {
                origin: positionToLatLng(origin),
                destination,
                travelMode: getTravelMode(travelMode),
            };
            directionsService.route(request, (result, status) => {
                var _a, _b, _c, _d;
                if (!result) {
                    return;
                }
                if (status === "ZERO_RESULTS") {
                    return;
                }
                if (status === "OK") {
                    const path = result.routes[0].legs[0];
                    const directionsInfo = {
                        steps: path.steps.map(s => s.instructions),
                        time: (_b = (_a = path.duration) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : "",
                        distance: (_d = (_c = path.distance) === null || _c === void 0 ? void 0 : _c.text) !== null && _d !== void 0 ? _d : "",
                    };
                    // disable all data unrelated to driving directions
                    markercluster.setMap(null);
                    markersMap.user.setVisible(false);
                    // enable all data related to driving directions
                    setDirectionsInfo(directionsInfo);
                    directionsRenderer.setMap(map);
                    directionsRenderer.setDirections(result);
                    markersMap.directionsOrigin.setPosition({ lat: origin.latitude, lng: origin.longitude });
                    markersMap.directionsOrigin.setVisible(true);
                    markersMap.destinationStore.setPosition({ lat: store.latitude, lng: store.longitude });
                    setMarkerIcon(markersMap.destinationStore, store);
                    markersMap.destinationStore.setVisible(true);
                }
            });
        }
        else {
            // disable all data related to driving directions
            directionsRenderer.setDirections({ routes: [] });
            markersMap.directionsOrigin.setVisible(false);
            markersMap.destinationStore.setVisible(false);
            // enable all data unrelated to driving directions
            markersMap.user.setVisible(true);
            markercluster.setMap(map);
        }
    }, [origin, store, travelMode]);
    function setMarkerIcon(marker, store) {
        if (configuration.map.google.storeMarkerIcons) {
            for (const label of store.storeTypeLabels) {
                if (configuration.map.google.storeMarkerIcons[label]) {
                    marker.setIcon({
                        url: configuration.map.google.storeMarkerIcons[label],
                        scaledSize: new google.maps.Size(40, 40),
                    });
                    break;
                }
            }
        }
    }
    function getInfoWindow(store) {
        if (!infoWindow) {
            infoWindow = new google.maps.InfoWindow();
        }
        const body = document.createElement("div");
        body.classList.add("rt-info-window");
        const name = document.createElement("p");
        name.classList.add("rt-info-window__name");
        name.innerText = `CRLAB ${store.city}`;
        const centerName = document.createElement("p");
        centerName.classList.add("rt-info-window__centername");
        centerName.innerText = store.name;
        const address = document.createElement("p");
        address.classList.add("rt-info-window__location");
        address.innerText = `${store.address1} ${store.postalCode} ${store.city}`;
        const phone = document.createElement("a");
        phone.classList.add("rt-info-window__phone");
        phone.innerText = store.phone;
        phone.href = `tel:${store.phone}`;
        const website = document.createElement("a");
        website.classList.add("rt-info-window__website");
        website.innerText = store.website;
        website.href = store.website;
        website.onclick = (e) => {
            e.stopPropagation();
            analyticsHandler.dispatch({
                type: "click_website",
                subtype: "map",
                payload: { store },
            });
        };
        const buttons = document.createElement("div");
        buttons.classList.add("rt-info-window__buttons");
        const drivingDirectionsButton = document.createElement("button");
        drivingDirectionsButton.classList.add("rt-info-window__button-directions");
        drivingDirectionsButton.innerText = configuration.translations.map.infoWindow.directions;
        drivingDirectionsButton.onclick = (e) => {
            e.stopPropagation();
            analyticsHandler.dispatch({
                type: "click_directions",
                subtype: "map",
                payload: { store },
            });
            setStore(store);
        };
        const storeDetailsButton = document.createElement("button");
        storeDetailsButton.classList.add("rt-info-window__button-details");
        storeDetailsButton.innerText = configuration.translations.map.infoWindow.info;
        storeDetailsButton.onclick = (e) => {
            e.stopPropagation();
            analyticsHandler.dispatch({
                type: "click_store_details",
                subtype: "map",
                payload: { store },
            });
            window.open(store.storeLink);
        };
        buttons.appendChild(drivingDirectionsButton);
        buttons.appendChild(storeDetailsButton);
        body.appendChild(name);
        // We do not show the center name if we are in Italy or if the store name is exactly CRLAB ---- deleted store.country.tagISO31661Alpha2.toLowerCase() !== "it" &&
        if (store.name !== "CRLAB") {
            body.appendChild(centerName);
        }
        body.appendChild(address);
        body.appendChild(phone);
        if (store.country.tagISO31661Alpha2.toLowerCase() !== "it") {
            body.appendChild(website);
        }
        body.appendChild(buttons);
        infoWindow.setContent(body);
        return infoWindow;
    }
    return (React.createElement("div", { id: "rt-google-map", className: "rt-google-map", style: { width: "600px", height: "600px" } }));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: props => { },
    externalResources: api => [
        {
            type: EXTERNAL_RESOURCE.JS_GOOGLE_MAPS,
            priority: 1,
            load: (resolve, reject) => {
                const script = document.createElement("script");
                script.src = `https://maps.googleapis.com/maps/api/js?key=${api.google}`;
                script.onload = () => resolve("OK");
                script.onerror = e => reject(e);
                document.head.appendChild(script);
            },
        },
    ],
};
