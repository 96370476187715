import React, { useEffect, useMemo, useState } from "react";
import { DeviceContext } from "core/context/device";
import { getCurrentDevice } from "main/utils/device";
export function Component({ children }) {
    const [currentDevice, setCurrentDevice] = useState(getCurrentDevice);
    useEffect(() => {
        function handleResize() {
            const updatedDevice = getCurrentDevice();
            if (updatedDevice !== currentDevice) {
                setCurrentDevice(updatedDevice);
            }
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [currentDevice]);
    const contextValue = useMemo(() => ({
        currentDevice,
    }), [currentDevice]);
    return React.createElement(DeviceContext.Provider, { value: contextValue }, children);
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
