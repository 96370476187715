import { createContext, useContext } from "react";
export const StoresContext = createContext({
    allServices: [],
    allStores: [],
    filter: {},
    setFilter: (x) => { },
    filteredStores: [],
    visibleStores: [],
    setVisibleStores: (stores) => { },
    optionals: {},
});
StoresContext.displayName = "StoresContext";
export const useStores = () => useContext(StoresContext);
