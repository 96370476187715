import { useDevice } from "core/context/device";
import { DirectionsContext } from "core/context/directions";
import { usePosition } from "core/context/position";
import { useStores } from "core/context/stores";
import { Device } from "main/types/device";
import { TRAVEL_MODE } from "main/types/directions";
import { getGoogleMapsLink } from "main/utils/services/google/map";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { clearDirections, updateOrigin, updateStore, updateDirectionsInfo, updateTravelMode, updateUserPosition, } from "../directions/actions";
import { reducer } from "../directions/reducer";
function Component({ children, preselectedStoreCode }) {
    const { currentDevice } = useDevice();
    const { userPosition } = usePosition();
    const { allStores } = useStores();
    const [directions, dispatch] = useReducer(reducer, {
        userPosition,
        origin: userPosition,
        store: allStores.find((s) => s.storeCode === preselectedStoreCode),
        directionsInfo: undefined,
        travelMode: TRAVEL_MODE.DRIVING,
    });
    useEffect(() => {
        if (currentDevice !== Device.DESKTOP) {
            resetDirections();
        }
    }, [currentDevice]);
    useEffect(() => {
        dispatch(updateUserPosition(userPosition));
    }, [userPosition]);
    const resetDirections = useCallback(() => {
        dispatch(clearDirections());
    }, []);
    const setOrigin = useCallback((origin) => {
        dispatch(updateOrigin(origin));
    }, []);
    const setStore = useCallback((store) => {
        if (currentDevice === Device.DESKTOP) {
            dispatch(updateStore(store));
        }
        else {
            const storeLink = getGoogleMapsLink(store);
            window.open(storeLink, "_blank");
        }
    }, [currentDevice]);
    const setDirectionsInfo = useCallback((directionsInfo) => {
        dispatch(updateDirectionsInfo(directionsInfo));
    }, []);
    const setTravelMode = useCallback((travelMode) => {
        dispatch(updateTravelMode(travelMode));
    }, []);
    const contextValue = useMemo(() => ({
        directions,
        resetDirections,
        setDirectionsInfo,
        setOrigin,
        setStore,
        setTravelMode,
    }), [directions, currentDevice]);
    return React.createElement(DirectionsContext.Provider, { value: contextValue }, children);
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
