import React, { useMemo, useState } from "react";
import { StoresContext } from "core/context/stores";
import { usePosition } from "core/context/position";
import { selectPredicate } from "main/utils/filters";
import { getDistanceFn } from "main/utils/position";
import { sortByDistance } from "main/utils/sort";
import { createOpeningTextCache } from "main/utils/time";
function Component({ configuration, children, allStores, allServices, preselectedFilters, }) {
    const { currentPosition } = usePosition();
    const [filter, setFilter] = useState(() => preselectedFilters !== null && preselectedFilters !== void 0 ? preselectedFilters : {});
    const filteredStores = useMemo(() => {
        const outerLogic = configuration.filters.mainFilteringLogic;
        const filterEntries = Object.entries(filter);
        // creating an array of predicates, one for each entry of the filter object
        const predicates = filterEntries.map(([filterName, filterValue]) => {
            const innerLogic = configuration.filters.availableFilters[filterName];
            return selectPredicate(filterName, filterValue, innerLogic);
        });
        // composing all predicates into a single one using an arbitrary logic
        const pred = predicates.length === 0
            ? (_) => true
            : outerLogic === "and"
                ? (s) => predicates.every(p => p(s))
                : (s) => predicates.some(p => p(s));
        // filtering all stores based on the single predicate
        const filtered = allStores.filter(pred);
        // sorting the stores by distance from the current position
        const getDistance = getDistanceFn(currentPosition.latitude, currentPosition.longitude);
        for (let i = 0; i < filtered.length; ++i) {
            filtered[i].distance = getDistance(filtered[i].latitude, filtered[i].longitude);
        }
        return filtered.sort(sortByDistance);
    }, [allStores, filter, currentPosition]);
    const [visibleStores, setVisibleStores] = useState([]);
    // const reactToMapChanges = useCallback(
    //   (center: Position, zoom: number, containsCb: (s: Store) => boolean) => {
    //     const stores = filteredStores.filter(containsCb);
    //     setVisibleStores(stores);
    //   },
    //   [filteredStores]
    // );
    const openingTimesCache = useMemo(() => {
        return createOpeningTextCache({
            language: configuration.language,
            stores: allStores,
            serverTime: configuration.time,
            openingTimes: configuration.translations.openingTimes,
        });
        // no dependencies because allStores never changes
    }, []);
    const optionals = useMemo(() => ({
        // reactToMapChanges,
        openingTimesCache,
    }), [filteredStores]);
    const contextValue = useMemo(() => ({
        allServices,
        allStores,
        filter,
        filteredStores,
        setFilter,
        visibleStores,
        setVisibleStores,
        optionals,
    }), [allServices, allStores, filter, filteredStores, visibleStores, optionals]);
    return React.createElement(StoresContext.Provider, { value: contextValue }, children);
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: props => { },
};
