// * implements the useful "piping" feature (function composition)
export function pipe(initialValue, ...fns) {
    return fns.reduce((y, f) => f(y), initialValue);
}
// * given a props object, it creates a new object of type T filtering out its entries based on a predicate
export function getFilteredProps(obj, pred) {
    const entries = Object.entries(obj);
    return Object.fromEntries(entries.filter(pred));
}
export function loadExternalResources(loadCallback) {
    return new Promise((resolve, reject) => loadCallback(resolve, reject));
}
export function debounce(ms, fn, ...args) {
    let timerId;
    return () => {
        clearTimeout(timerId);
        timerId = setTimeout(() => fn(...args), ms);
    };
}
export function getDebounce(ms) {
    let timerId;
    return (fn, ...args) => () => {
        clearTimeout(timerId);
        timerId = setTimeout(() => fn(...args), ms);
    };
}
export function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
